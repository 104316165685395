import React from "react";
import logo from "../images/logo.png";
import './job.css';
import { Link } from "react-router-dom";
// import { Button } from "react-bootstrap";

function JobNavbar() {
    return (
        <>
            <div className="fullhdr">
                <div className="d-flex">
                <div className="container topdashhdr d-flex align-items-center">
                    <div className="dashlogo">
                        <Link to={'/'}><img src={logo} alt="logo" /></Link>
                    </div>
                    <div className="pagename">
                        <p>Find Jobs</p>
                    </div>
                </div>
                <div className="">
                    <Link to={'/login'} className="sitebtn jobsearch bgblue" type="submit">
                        Login
                    </Link>
                </div>
                </div>
            </div>
        </>
    );
}
export default JobNavbar;