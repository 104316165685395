import moment from 'moment';


export const DateConverts = (date) => {
    if(!date){
        return '';
    }
    return moment(date).format('DD/MM/YYYY');
}

export const camelCaseHandle = (str) => {
    if (!str) {
        return '';
    }
    return str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (match, char) => char.toUpperCase());
};

export const changeJobType = (JobTypes) => {

    switch(JobTypes){
        case 'OnContract':
          return  'On Consultant'
        case 'On Contract':
          return  'On Consultant'
        case 'OnRole':
           return  'On Role'
        case 'On Role':
           return  'On Role'
        default:
            return JobTypes
    }
}