
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FileUpload from "../Components/FileUpload";
import InputGroup from 'react-bootstrap/InputGroup';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CustomAsyncSelectField from '../job-portal/JobSearchForm/SelectComponents'
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchRecordsById } from "../Redux/Slices/JobListApi";
import axios from "axios";
import config from "../Config/Config";
import { apiHeaderTokenMultiPart, apiHeaderToken } from "../Headers/CustomeHeaders";
import { useState } from "react";

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        //maxWidth: '24rem',
        width: '100%',
        height: '44px',

    }),
    menu: (provided) => ({
        ...provided,
        borderTop: '1px solid #D2C9FF',
        zIndex: '9',
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px solid #D2C9FF',
        color: state.isSelected ? '#fff' : '#000',
        backgroundColor: state.isSelected ? '#4CAF50' : state.isFocused ? '#80CBC4' : provided.backgroundColor,
        '&:hover': {
            backgroundColor: '#80CBC4',
            color: '#fff',
        },
    }),
};


const Applyform = () => {

    const [socialMedia, setSocialMedia] = useState([])
    const fileName = useSelector(state => state.files.file)
    const dispatch = useDispatch();
    const jobs = useSelector((state) => state.jobs.jobRecordsById)
    const { id } = useParams();
    // console.log(jobs)
    useEffect(() => {
        dispatch(fetchRecordsById({ id: id }))
    }, [dispatch, id])

    //    const Designation = jobs.


    const TotalOptions = (years, months) => {
        let options = [];
        if (years) {
            for (let i = 0; i <= 15; i++) {
                options.push(<option key={i} value={i}>{i}</option>);
            }
        } else if (months) {
            for (let i = 0; i <= 12; i++) {
                options.push(<option key={i} value={i}>{i}</option>);
            }
        }
        return options;
    };

    // high order function
    const SocialMedia = () => {
        let options = [];

        if (socialMedia.length > 0) {

            for (let i = 0; i <= socialMedia?.length - 1; i++) {
                options.push(<option key={socialMedia[i]?.name} value={socialMedia[i]?.name}>{socialMedia[i]?.name}</option>);
            }
        }
        return options;
    };

    const handleDynamicInputFields = (inputFields) => {
        if (!inputFields) {
            return null;
        }
        let components = [];
        inputFields.forEach((field) => {
            if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Applied From') {
                components.push(
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Hear from</Form.Label>
                        <Form.Select
                            name="appliedFrom"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.appliedFrom}
                            hidden={field?.action === "off" ? true : false}
                            isInvalid={formik.touched.appliedFrom && formik.errors.appliedFrom}
                        >
                            <option value="" disabled hidden>
                                Select Applied From
                            </option>
                            {SocialMedia()}
                        </Form.Select>
                    </Form.Group>

                )
            } else if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Reference Employee') {
                components.push(
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Reference Employee (if any)</Form.Label>
                        <Form.Control
                            name="referenceEmployee"
                            onChange={(e) => {
                                const regex = /^[A-Za-z()-/ ]+$/;
                                if (regex.test(e.target.value) || e.target.value === '') {
                                    formik.handleChange(e);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            hidden={field?.action === "off" ? true : false}
                            placeholder="Reference Employee"
                            value={formik.values.referenceEmployee}
                        />
                    </Form.Group>
                )
            }
        })

        return components;

    }

    const notify = (message) => toast.success(message, {
        position: "top-right",
        theme: "dark",
    });

    const mobileNumberCurrentEmployer = (inputFields) => {
        if (!inputFields) {
            return null;
        }

        let components = [];
        inputFields.forEach((field) => {
            if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Phone') {
                components.push(
                    <Form.Group as={Col} controlId="formGridAddress1">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                            name="mobileNumber"
                            type="text"
                            maxLength={10}
                            placeholder="Enter mobile number"
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d{0,10}$/.test(value)) {
                                    formik.setFieldValue('mobileNumber', value);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            hidden={field?.action === "off" ? true : false}
                            value={formik.values.mobileNumber}
                            isInvalid={formik.touched.mobileNumber && formik.errors.mobileNumber}
                        />
                    </Form.Group>
                )
            } else if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Employer') {
                components.push(
                    <Form.Group as={Col} controlId="formGridAddress2">
                        <Form.Label>Current Employer</Form.Label>
                        <Form.Control
                            name="currentEmployer"
                            placeholder="abc pvt. ltd."
                            onChange={(e) => {
                                const regex = /^[A-Za-z()-/ ]+$/;
                                if (regex.test(e.target.value) || e.target.value === '') {
                                    formik.handleChange(e);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            hidden={field?.action === "off" ? true : false}
                            value={formik.values.currentEmployer}
                            isInvalid={formik.touched.currentEmployer && formik.errors.currentEmployer}
                        />

                    </Form.Group>
                )
            }
        })
        return components;
    }

    const DesignationAndLocation = (inputFields) => {
        if (!inputFields) {
            return null;
        }
        let components = [];
        inputFields.forEach((field) => {
            if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'designation') {
                components.push(
                    <Form.Group as={Col} controlId="formGridAddress1">
                        <Form.Label>Current Designation</Form.Label>
                        <Form.Control
                            name="currentDesignation"
                            placeholder={jobs.status === 'succeeded' && jobs?.data?.designation}
                            onChange={(e) => {
                                const regex = /^[A-Za-z()-/ ]+$/;
                                if (regex.test(e.target.value) || e.target.value === '') {
                                    formik.handleChange(e);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            hidden={field?.action === "off" ? true : false}
                            value={formik.values.currentDesignation}
                            isInvalid={formik.touched.currentDesignation && formik.errors.currentDesignation}
                        />
                    </Form.Group>
                )
            } else if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Location') {
                components.push(
                    <Form.Group as={Col} controlId="formGridAddress2">
                        <Form.Label>Location</Form.Label>
                        <CustomAsyncSelectField
                            field={{
                                name: 'location',
                                value: formik.values.location,
                                onBlur: formik.handleBlur,
                                isInvalid: formik?.touched?.location && formik?.errors?.location
                            }}
                            form={formik}
                            hidden={field?.action === "off" ? true : false}
                            customStyles={customStyles}
                            isInvalid={formik?.touched?.location && formik?.errors?.location}
                        />
                    </Form.Group>
                )
            }
        })
        return components;
    }

    const currentCTCexpectedCTC = (inputFields) => {
        if (!inputFields) {
            return null;
        }
        let components = [];
        inputFields.forEach((field) => {
            if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Current CTC') {
                components.push(
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Current CTC (LPA)</Form.Label>
                        <Form.Control
                            name="currentCTC"
                            placeholder="3.5"
                            type="text"
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9.]/g, '');
                                if (value.length <= 10) {
                                    formik.setFieldValue("currentCTC", value);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            hidden={field?.action === "off" ? true : false}
                            value={formik.values.currentCTC}
                            isInvalid={formik.touched.currentCTC && formik.errors.currentCTC}
                        />
                    </Form.Group>
                )
            } else if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Expected CTC') {
                components.push(
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Expected CTC (LPA)</Form.Label>
                        <Form.Control
                            name="expectedCTC"
                            placeholder="3.5"
                            type="text"
                            onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9.]/g, '');
                                if (value.length <= 10) {
                                    formik.setFieldValue("expectedCTC", value);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            hidden={field?.action === "off" ? true : false}
                            value={formik.values.expectedCTC}
                            isInvalid={formik.touched.expectedCTC && formik.errors.expectedCTC}
                        />
                    </Form.Group>
                )
            }
        })
        return components;
    }


    const NoticePeriodLastworking = (inputFields) => {
        if (!inputFields) {
            return null;
        }
        let components = [];
        inputFields.forEach((field) => {
            if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Notice Period') {
                components.push(
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Notice Period</Form.Label>
                        <div className="selectflex">
                            <InputGroup>
                                <Form.Select
                                    name="noticePeriod"
                                    onChange={formik.handleChange}
                                    hidden={field?.action === "off" ? true : false}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.noticePeriod}
                                    isInvalid={formik.touched.noticePeriod && formik.errors.noticePeriod}
                                >
                                    <option value={0}>0</option>
                                    <option value={10}>10</option>
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                </Form.Select>
                                <InputGroup.Text>Days</InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Form.Group>
                )
            } else if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Last Working Day') {
                components.push(
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Last working day (In case of Serving)</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="date"
                                name="lastWorkingDay"
                                onChange={formik.handleChange}
                                hidden={field?.action === "off" ? true : false}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastWorkingDay}
                                isInvalid={formik.touched.lastWorkingDay && formik.errors.lastWorkingDay}
                            />
                            <DateRangeIcon />
                        </InputGroup>
                    </Form.Group>
                )
            }
        })
        return components;
    }

    const TotalRelevantExperience = (inputFields) => {
        if (!inputFields) {
            return null;
        }
        let components = [];
        inputFields.forEach((field) => {
            if (['Mandatory', 'Optional'].includes(field.action) && field.label === 'Experience') {
                components.push(
                    <>
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label>Total Experience</Form.Label>
                            <div className="selectflex">
                                <InputGroup>
                                    <Form.Select
                                        name="totalExperienceYears"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        hidden={field?.action === "off" ? true : false}
                                        value={formik.values.totalExperienceYears}
                                        isInvalid={formik.touched.totalExperienceYears && formik.errors.totalExperienceYears}
                                    >
                                        {
                                            TotalOptions(true, false)
                                        }
                                    </Form.Select>
                                    <InputGroup.Text>Years</InputGroup.Text>
                                </InputGroup>
                                <InputGroup>
                                    <Form.Select
                                        name="totalExperienceMonths"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        hidden={field?.action === "off" ? true : false}
                                        value={formik.values.totalExperienceMonths}
                                        isInvalid={formik.touched.totalExperienceMonths && formik.errors.totalExperienceMonths}
                                    >
                                        {
                                            TotalOptions(false, true)
                                        }
                                    </Form.Select>
                                    <InputGroup.Text>Months</InputGroup.Text>
                                </InputGroup>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Relevant Experience</Form.Label>
                            <div className="selectflex">
                                <InputGroup>
                                    <Form.Select
                                        name="relevantExperienceYears"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        hidden={field?.action === "off" ? true : false}
                                        value={formik.values.relevantExperienceYears}
                                        isInvalid={formik.touched.relevantExperienceYears && formik.errors.relevantExperienceYears}
                                    >
                                        {
                                            TotalOptions(true, false)
                                        }
                                    </Form.Select>
                                    <InputGroup.Text>Years</InputGroup.Text>
                                </InputGroup>
                                <InputGroup>
                                    <Form.Select
                                        name="relevantExperienceMonths"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        hidden={field?.action === "off" ? true : false}
                                        value={formik.values.relevantExperienceMonths}
                                        isInvalid={formik.touched.relevantExperienceMonths && formik.errors.relevantExperienceMonths}
                                    >
                                        {
                                            TotalOptions(false, true)
                                        }
                                    </Form.Select>
                                    <InputGroup.Text>Months</InputGroup.Text>
                                </InputGroup>
                            </div>
                        </Form.Group>
                    </>
                )
            }
        })
        return components;
    }


    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobileNumber: "",
            currentEmployer: "",
            currentDesignation: "",
            location: "",
            totalExperienceYears: 0,
            totalExperienceMonths: 0,
            relevantExperienceYears: 0,
            relevantExperienceMonths: 0,
            currentCTC: "",
            expectedCTC: "",
            noticePeriod: 0,
            lastWorkingDay: "",
            appliedFrom: "",
            referenceEmployee: "",
        },

        validationSchema: Yup.object().shape(
            jobs?.status === "succeeded"
                ? jobs.data.form_personal_data.reduce((schema, field) => {
                    //console.log(field)
                    switch (field.label) {
                        case 'Candidate Full name':
                            schema.name = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Email':
                            schema.email = field.action === 'Mandatory' ? Yup.string().email("Invalid email").required("Required") : Yup.string().email("Invalid email");
                            break;
                        case 'Phone':
                            schema.mobileNumber = field.action === 'Mandatory'
                                ? Yup.string()
                                    .required("Required")
                                    .matches(/^\d{10}$/, "Must be exactly 10 digits")
                                : Yup.string()
                                    .matches(/^\d{10}$/, "Must be exactly 10 digits");
                            break;
                        case 'designation':
                            schema.currentDesignation = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Experience':
                            schema.totalExperienceYears = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            schema.totalExperienceMonths = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            schema.relevantExperienceYears = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            schema.relevantExperienceMonths = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Location':
                            schema.location = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Employer':
                            schema.currentEmployer = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Current CTC':
                            schema.currentCTC = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Expected CTC':
                            schema.expectedCTC = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Notice Period':
                            schema.noticePeriod = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Applied From':
                            schema.appliedFrom = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Reference Employee':
                            schema.referenceEmployee = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        case 'Last Working Day':
                            schema.lastWorkingDay = field.action === 'Mandatory' ? Yup.string().required("Required") : Yup.string();
                            break;
                        default:
                            break;
                    }
                    return schema;
                }, {})
                : {}
        ),

        validate: (() => {
            if (!fileName) {
                return toast.warn('Please Select The Resume', {
                    position: "top-right",
                    theme: "dark"
                });
            }
        }),
        onSubmit: (values, { resetForm }) => {
            const formData = new FormData();
            formData.append('job_id', jobs.data._id);
            formData.append('job_title', jobs.data.job_title);
            formData.append('job_type', jobs.data.job_type);
            formData.append('project_id', jobs.data.project_id);
            formData.append('project_name', jobs.data.project_name);
            formData.append('designation_id', jobs.data?.designation_id);
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('mobile_no', values.mobileNumber);
            formData.append('filename', fileName);
            formData.append('designation', values.currentDesignation);
            formData.append('current_employer_mobile', "454455455665");
            formData.append('current_employer', values.currentEmployer);
            formData.append('location', values.location);
            formData.append('total_experience', `${values.totalExperienceYears} Year(s) ${values?.totalExperienceMonths} Months`);
            formData.append('relevant_experience', `${values.relevantExperienceYears} Year(s) ${values?.relevantExperienceMonths} Months`);
            formData.append('current_ctc', values.currentCTC);
            formData.append('expected_ctc', values.expectedCTC);
            formData.append('notice_period', values.noticePeriod);
            formData.append('last_working_day', values.lastWorkingDay);
            formData.append('applied_from', values.appliedFrom);
            formData.append('reference_employee', values.referenceEmployee);
            formData.append('department', jobs.data.department);

            // if (!fileName) {
            //     return toast.warn('Please Select The Resume', {
            //         position: "top-right",
            //         theme: "dark"
            //     });
            // }

            axios.post(`${config.BASE_URL}/front/applyJob`, formData, apiHeaderTokenMultiPart(config.API_TOKEN))
                .then((response) => {
                    if (response.data?.status) {
                        notify(response.data.message);
                        resetForm();
                    }
                })
                .catch((err) => {
                    toast.warn(err.response.data.message, {
                        position: "top-right",
                        theme: "dark",
                    });
                });
        },
    });

    const getAppliedFromList = async () => {
        const payload = { status: 'Active' };
        try {
            let response = await axios.post(`${config.BASE_URL}/candidate/getAppliedFromList`, payload, apiHeaderToken(config.API_TOKEN));
            setSocialMedia(response?.data?.data)

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAppliedFromList();
    }, []);

    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                theme="dark"
                limit={1}
            />
            <div className="container">
                <div className="contentwrap">
                    <div className="applyform profileform">
                        <h4>Complete few Information to apply for this job</h4>
                        <Form onSubmit={formik.handleSubmit} acceptCharset="UTF-8">
                            <div className="formwrap">
                                <h5>1. Upload Resume</h5>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <FileUpload />
                                    </Form.Group>
                                </Row>
                                <h5>2. Personal Information</h5>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Enter name"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const regex = /^[a-zA-Z\s]*$/;
                                                if (!regex.test(value)) {
                                                    return;
                                                }
                                                if (value.length <= 20) {
                                                    formik.setFieldValue('name', value);
                                                }
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                            isInvalid={formik.touched.name && formik.errors.name}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Email ID</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="example@gmail.com"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            isInvalid={formik.touched.email && formik.errors.email}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    {
                                        mobileNumberCurrentEmployer(jobs.status === 'succeeded' && jobs.data.form_personal_data)
                                    }
                                </Row>
                                <Row className="mb-3">
                                    {
                                        DesignationAndLocation(jobs.status === 'succeeded' && jobs.data.form_personal_data)
                                    }
                                </Row>
                                <Row className="mb-3">
                                    {
                                        TotalRelevantExperience(jobs.status === 'succeeded' && jobs.data.form_personal_data)
                                    }
                                </Row>
                                <Row className="mb-3">
                                    {
                                        currentCTCexpectedCTC(jobs.status === 'succeeded' && jobs.data.form_personal_data)
                                    }

                                </Row>
                                <Row className="mb-3">
                                    {
                                        NoticePeriodLastworking(jobs.status === 'succeeded' && jobs.data.form_personal_data)
                                    }
                                </Row>

                                <Row className="mb-3">
                                    {
                                        handleDynamicInputFields(jobs.status === 'succeeded' && jobs.data.form_personal_data)
                                    }
                                </Row>


                            </div>
                            <div className="finalsubmit">
                                <Button className="sitebtn profilesub" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Applyform;







