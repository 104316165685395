
import React from 'react';
import { FiCalendar } from "react-icons/fi";
import { GiSandsOfTime } from "react-icons/gi";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { DateConverts , changeJobType} from '../utils/DateConvertion';
import ShareButton from './JobShareOption/ShareButton';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const Job_description = ({ jobs }) => {
    // console.log(jobs,"mmmmmmmmmmmm")
    const jobTitle = jobs?.job_title;
    const jobDescription = jobs?.description;
    const jobImage = "https://front-hrms.dtsmis.in/favicon.png";
    const jobUrl = `https://front-hrms.dtsmis.in/job-details/${jobs?._id}&title=${jobTitle}&description=${jobDescription}`;

    return (
        <>
            <Helmet>
                <title>{jobTitle}</title>
                <meta name="keywords" content={jobTitle} />
                <meta name="description" content={jobDescription} />

                {/* Twitter OG */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={jobTitle} />
                <meta name="twitter:description" content={jobDescription} />
                <meta name="twitter:url" content={jobUrl} />
                <meta property="twitter:image" content={jobImage} />

                {/* Facebook OG */}
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content={jobTitle} />
                <meta property="og:description" content={jobDescription} />
                <meta property="og:url" content={jobUrl} />
                <meta property="og:site_name" content="https://front-hrms.dtsmis.in/" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={jobImage} />

                {/* LinkedIn OG */}
                <meta property="linkedin:title" content={jobTitle} />
                <meta property="linkedin:description" content={jobDescription} />
                <meta property="linkedin:type" content="website" />
                 <meta property="linkedin:site_name" content="https://front-hrms.dtsmis.in/" />
                <meta property="linkedin:url" content={jobUrl} />
                <meta property="linkedin:image" content={jobImage} />
            </Helmet>

            <div className="detailsbox">
                <div className="dtlheadr">
                    <div className="job_postn">
                        <span className="work_loc">{changeJobType(jobs?.job_type)}</span>
                        <h3>{jobTitle}</h3>
                        <b>{jobs?.project_name}</b><br/>
                       <p>{jobs?.designation}</p>
                        <span>{jobs?.location[0]?.name}</span>
                    </div>
                    <div className="job_summry">
                        <div className="jbsum">
                            <span>Job Type</span>
                            <p><FiCalendar /> {jobs?.job_type}</p>
                        </div>
                        <div className="jbsum">
                            <span>Salary Range</span>
                            <p><MdOutlineCurrencyRupee /> {jobs?.salary_range}</p>
                        </div>
                        <div className="jbsum">
                            <span>Deadline</span>
                            <p><GiSandsOfTime /> {DateConverts(jobs?.deadline)}</p>
                        </div>
                        <div className="apply_share">
                            <div className="btn_date">
                                <Link className='sitebtn jobsearch bgblue' type='submit' to={`/apply/${jobs?._id}`}>Apply Now</Link>
                                <span>{moment().format('DD/MM/YYYY, h:mm a')}</span>
                            </div>
                            <ShareButton Job_description={jobs} />
                        </div>
                    </div>
                </div>
                <div className="dtl_body">
                    <div className="job_benfit">
                        <h5>Benefits</h5>
                        <ul className="benefits">
                            {jobs?.benefits?.map((benefit, index) => (
                                <li key={index}>{benefit.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='job_description' dangerouslySetInnerHTML={{ __html: jobs?.description }} />
                </div>
            </div>
        </>
    );
};

export default Job_description;
